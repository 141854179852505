import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import { useFetchApi } from './use-fetch-api';

interface PatchCashFlowCategoryRequest {
  categoryId: string | null;
  subcategoryId: string;
  payload: { order?: number };
}

export const useUpdateCashFlowSubcategory = (): UseMutationResult<
  void,
  Error,
  PatchCashFlowCategoryRequest
> => {
  const fetchApi = useFetchApi();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const patchCashFlowSubcategory = async (request: PatchCashFlowCategoryRequest): Promise<void> => {
    const { categoryId, subcategoryId, payload } = request;

    const response = await fetchApi(
      `${cashFlowCategoriesNamespace}/categories/${categoryId}/subcategories/${subcategoryId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) throw Error(`Subcategory ${subcategoryId} could not be updated`);
  };

  return useMutation({
    mutationFn: patchCashFlowSubcategory,
    onError: () => {
      toastFlashMessages.toastError('We couldn’t reorganize your categories. Can you try again?');
    },
  });
};
