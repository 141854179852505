import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import type { Transaction } from 'qonto/react/graphql';
import { useFetchApi } from './use-fetch-api';
import { useOrganizationManager } from './use-organization-manager';

interface MutationVars {
  commentContent: string;
}

export const useCreateComment = (
  transaction: Transaction
): {
  mutate: UseMutateFunction<void, Error, MutationVars>;
  isPending: boolean;
  isError: boolean;
} => {
  const fetchApi = useFetchApi();
  const queryClient = useQueryClient();
  const {
    membership: { membershipId: commentAuthorId },
  } = useOrganizationManager();

  const { thread, id: transactionId, initiatorId } = transaction;

  const createComment = async (variables: MutationVars): Promise<void> => {
    const { commentContent } = variables;
    let endPointPath;
    let payload;

    if (!thread) {
      endPointPath = `v1/threads`;
      payload = {
        data: {
          type: 'thread',
          attributes: {
            attached_to_id: transactionId,
            attached_to_type: 'transaction',
            message: {
              content: commentContent,
            },
            participants: !initiatorId || commentAuthorId === initiatorId ? [] : [initiatorId],
          },
        },
      };
    } else {
      endPointPath = `v1/threads/${thread.id}/messages`;
      payload = {
        data: {
          type: 'message',
          attributes: {
            content: commentContent,
          },
        },
      };
    }

    const response = await fetchApi(endPointPath, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw Error('Your comment could not be saved');
    }
  };

  const { isPending, isError, mutate } = useMutation({
    mutationFn: createComment,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ['search-transactions-graphql'] });
    },
  });

  return {
    mutate,
    isPending,
    isError,
  };
};
